<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
    </v-row>
    <v-row justify="center">
      <v-card class="mx-auto">
        <v-card-text>
          <v-container fluid>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-row class="d-flex justify-start ml-2 mr-2">
                  <v-col cols="12" md="4">
                    <v-select
                      @change="checkType"
                      outlined
                      v-model="product.productType"
                      label="Tipo"
                      :items="['PRODUTO', 'SERVICO']"
                      required
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-end align-end mr-1 ml-1">
                  <v-col cols="12" sm="4">
                    <v-switch
                      :disabled="!visibleFields"
                      v-model="product.stockControl"
                      label="Controlar Estoque"
                    ></v-switch>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="4"
                    class="mt-n8"
                    v-if="product.productType === 'SERVICO'"
                  >
                    <span>Comissão Serviço</span>
                    <money
                      class="money"
                      v-model="product.serviceComissionFee"
                      v-bind="money"
                    ></money>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="4"
                    class="mt-n8"
                    v-if="product.productType === 'PRODUTO'"
                  >
                    <span>Preço Custo</span>
                    <money
                      class="money"
                      v-model="product.costPrice"
                      v-bind="money"
                    ></money>
                  </v-col>

                  <v-col cols="6" sm="4" class="mt-n8">
                    <span>Preço Venda</span>
                    <money class="money" v-model="product.price" v-bind="money"></money>
                  </v-col>
                </v-row>
                <v-col cols="12" :md="product.productType === 'PRODUTO' ? '8' : '12'">
                  <v-text-field
                    outlined
                    v-model="product.name"
                    label="Nome"
                    required
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  v-if="visibleFields && product.productType === 'PRODUTO'"
                >
                  <v-text-field
                    outlined
                    v-model="product.model"
                    label="Modelo"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  class="mt-n8"
                  md="5"
                  v-if="product.productType === 'SERVICO'"
                >
                  <v-select
                    outlined
                    v-model="product.timeService"
                    label="Tempo Execução Minutos"
                    :items="serviceTimes"
                    required
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                  ></v-select>
                </v-col>

                <v-col
                  cols="12"
                  :sm="product.productType === 'SERVICO' ? '7' : '6'"
                  class="mt-n8"
                >
                  <v-autocomplete
                    outlined
                    :items="categories"
                    v-model="product.categoryId"
                    label="Selecionae uma Categoria*"
                    item-text="name"
                    item-value="id"
                    :loading="loading"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                  ></v-autocomplete>
                </v-col>

                <v-col
                  cols="6"
                  md="3"
                  class="mt-n8"
                  v-if="visibleFields && product.productType === 'PRODUTO'"
                >
                  <v-text-field
                    outlined
                    v-model="product.manufacturer"
                    label="Fabricante"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                  class="mt-n8"
                  v-if="visibleFields && product.productType === 'PRODUTO'"
                >
                  <v-text-field
                    outlined
                    v-model="product.brand"
                    label="Marca"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="6"
                  md="3"
                  class="mt-n8"
                  v-if="visibleFields && product.productType === 'PRODUTO'"
                >
                  <v-select
                    outlined
                    v-model="product.unity"
                    label="Unidade"
                    :items="['UN', 'MT', 'LT', 'KG']"
                    required
                  ></v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  class="mt-n8"
                  v-if="visibleFields && product.productType === 'PRODUTO'"
                >
                  <v-text-field
                    outlined
                    v-model="product.ncm"
                    label="NCM"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="5"
                  class="mt-n8"
                  v-if="visibleFields && product.productType === 'PRODUTO'"
                >
                  <v-text-field
                    outlined
                    v-model="product.barCode"
                    label="Código de Barras"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-textarea
                    outlined
                    label="Descrição Completa"
                    v-model="product.description"
                    rows="7"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn text @click.native="openFileDialog">
                    {{ fileName ? fileName : "Imagem" }}
                    <v-icon right dark>mdi-camera</v-icon>
                  </v-btn>
                  <input
                    ref="fileRef"
                    type="file"
                    id="file-upload"
                    style="display: none"
                    @change="handleFileUpload"
                  />
                  <v-img
                    height="160"
                    width="160"
                    :src="urlPreview"
                    @click.native="openFileDialog"
                  ></v-img>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-row class="d-flex justify-end">
                  <v-col cols="12" md="4">
                    <v-btn
                      block
                      dark
                      :disabled="!valid"
                      :loading="loading"
                      color="#2ca01c"
                      @click="validate()"
                    >
                      Salvar
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn block outlined color="blue darken-1" text to="/base/products">
                      Cancelar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-row>

    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false">Ok</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { Money } from "v-money";
import PageHeader from "@/components/core/PageHeader.vue";
export default Vue.extend({
  name: "ProductsForm",
  components: { Money, PageHeader },
  data: () => ({
    valid: true,
    title: "Cadastro de Produtos",
    visibleFields: true,
    serviceTimes: [
      15,
      30,
      45,
      60,
      75,
      90,
      105,
      120,
      135,
      150,
      165,
      180,
      195,
      210,
      225,
      240,
      255,
      270,
      285,
      300,
      315,
      330,
      345,
      360,
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Produtos",
        disabled: false,
        href: "/base/products",
      },
      {
        text: "Cadastrar",
        disabled: true,
        href: "/base/products",
      },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    products: [],
    categories: [],
    productId: null,
    file: "",
    fileName: "",
    urlPreview: "https://pedbem.blob.core.windows.net/pedbem/photo-99135_640.png",
    product: {
      name: "",
      description: "",
      categoryId: "",
      price: 0.0,
      imageUrl: "",
      productType: "PRODUTO",
      promotionalPrice: 0.0,
      serviceComissionFee: 0.0,
      timeService: 30,
      costPrice: 0.0,
      ncm: "",
      brand: "",
      bardCode: "",
      manufacturer: "",
      model: "",
      unity: "UN",
      stockControl: true,
    },
    search: "",
    loading: false,
    productId: "",
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    tributeId: "",
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) this.registerOrUpdate();
    },
    checkType() {
      if (this.product.productType === "SERVICO") {
        this.product.stockControl = false;
        this.product.brand = null;
        this.product.model = null;
        this.visibleFields = false;
      } else {
        this.product.stockControl = true;
        this.product.brand = "";
        this.product.model = "";
        this.visibleFields = true;
      }
    },
    handleFileUpload() {
      this.file = this.$refs.fileRef.files[0];
      this.fileName = this.$refs.fileRef.files[0].name;
      this.urlPreview = URL.createObjectURL(this.file);
    },
    openFileDialog() {
      document.getElementById("file-upload").click();
    },
    async getItem() {
      const productId = this.$route.params.id;
      if (productId) {
        this.title = "Editar";
        this.menu[2].text = "Editar";
        await http.get(`products/${productId}`).then(async (res) => {
          const { data } = await res;
          this.product = data;
          this.productId = data.id;
          this.urlPreview = data.imageUrl;
        });
      }
    },
    getCategories() {
      http.get("product/categories").then((res) => {
        this.categories = res.data;
      });
    },

    async registerOrUpdate() {
      this.loading = true;
      if (this.file) {
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("systemClientId", "pedbem");
        formData.append("companyId", "pedbem");
        formData.append("name", new Date().toString());
        formData.append("path", "erp/prodcuts");
        formData.append("type", "image-product");
        formData.append("public", 1);
        await http.post("base/files", formData).then(
          (d) => {
            this.product.imageUrl = d.data.imageUrl;
          },
          () => {
            this.snackbar.text =
              "Houve um erro ao fazer upload da IMAGEM, tente novamente";
            this.snackbar.opened = true;
            this.snackbar.color = "red";
          }
        );
      }
      this.product.promotionalPrice = this.product.price;
      if (this.productId) {
        await http.put(`products/${this.productId}`, this.product).then(
          () => {
            this.snackbar.text = "Produto atualizado com sucesso";
            this.snackbar.color = "success";
            this.snackbar.opened = true;
            this.loading = false;
            this.$router.push("/base/products");
          },
          (err) => {
            const { response } = err;
            const message =
              typeof response?.data === "string" ? response?.data : response?.data?.title;
            this.snackbar.text = "Erro ao atualizar o produto: " + message;
            this.snackbar.color = "red";
            this.snackbar.opened = true;
            this.loading = false;
          }
        );
      } else {
        await http.post("products", this.product).then(
          () => {
            this.snackbar.text = "Produto cadastrado com sucesso";
            this.snackbar.color = "success";
            this.snackbar.opened = true;
            this.loading = false;
            this.$router.push("/base/products");
          },
          (err) => {
            const { response } = err;
            const message =
              typeof response?.data === "string" ? response?.data : response?.data?.title;
            this.snackbar.text = "Erro ao cadastrar o produto: " + message;
            this.snackbar.color = "red";
            this.snackbar.opened = true;
            this.loading = false;
          }
        );
      }
      this.loading = false;
    },
  },
  async mounted() {
    await this.getCategories();
    await this.getItem();
  },
});
</script>
<style>
.money {
  background-color: rgb(0, 126, 0);
  height: 55px;
  padding: 15px;
  font-weight: bolder;
  font-size: 16px;
  width: 100%;
  color: aliceblue;
  border-radius: 5px;
}
.money:focus {
  outline: none !important;
  background-color: rgb(7, 85, 7);
}
</style>
